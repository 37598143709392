<template>
    <div class=" text-gray-300 overflow-hidden">
        <div ref="content" class="grid grid-cols-7 gap-5 p-5">
            <div class="col-span-5 overflow-scroll">
                <div class="mb-5">
                    <auto-search ref="autosearch" :text="true" @change="onSelectItem" :params="productIds" :placeholder="'Search product by name or sku'">
                        <template #default="item">
                            <div class="flex items-center">
                                <img :src="imgURL+'/public/img/products/200/'+item.item.images[0]" width="50" alt="">
                                <div class="ml-3">
                                    <h6 class="font-medium">{{item.item.name}}</h6>
                                    <div class="text-gray-400">{{item.item.sku}}</div>
                                </div>
                            </div>
                        </template>
                    </auto-search>
                </div>
                <table class="w-full">
                    <thead>
                        <tr>
                            <th class="py-2 text-center pr-1" style="widht: 50%">ITEMS</th>
                            <th class="py-2 text-right px-1" style="width: 12%">UNIT PRICE</th>
                            <th class="py-2 text-right px-1" style="width: 7%">QTY</th>
                            <th class="py-2 text-right px-1" style="width: 12%">DISCOUNT</th>
                            <th class="py-2 text-right px-1" style="width: 13%">TOTAL</th>
                            <th class="py-2 text-center px-1" style="width: 6%">SN</th>
                        </tr>
                    </thead>
                    <tbody class=" divide-y-4 divide-black">
                        <tr v-for="(item, i) in items" :key="item.idx" class="modal bg-gray-800 rounded-sm cursor-pointer group" :class="{'animated fadeInDown faster z-10': i == 0}">
                            <td class="pr-1 relative">
                                <div class="h-14 w-14 rounded-l-sm absolute bg-red-700 top-0 duration-200 bg-opacity-80 transform -translate-x-14 group-hover:translate-x-0">
                                    <a href="#" @click.prevent="onDeleteItem(item.idx)" class="h-full w-full flex items-center justify-center text-gray-50"><i class="icon-trash"></i></a>
                                </div>
                                <div class="p-1">
                                    <div class="flex items-center">
                                        <div class="rounded-sm overflow-hidden w-14 h-12">
                                            <div v-if="item.image">
                                                <img :src="imgURL+'/public/img/products/200/'+item.image">
                                            </div>
                                            <div v-else>
                                                <div class="h-12 w-14 bg-gray-700"></div>
                                            </div>
                                        </div>
                                        <div class="ml-2 w-full">{{item.name}}</div>
                                    </div>
                                </div>
                            </td>
                            <td class="px-1">
                                <div class="items-center flex justify-end">
                                    <autonumeric 
                                        :ref="'unitPrice'+i" 
                                        v-model="item.unitPrice" 
                                        :options="['integer', {modifyValueOnWheel : false}]" 
                                        @keydown.native.enter="onEnterUnitPrice(i)"
                                        class="border-b border-transparent group-hover:border-teal-500 focus:border-teal-600 w-full focus:outline-none bg-gray-800 text-right"
                                        :placeholder="'unit price'">
                                    </autonumeric>
                                </div>
                            </td>
                            <td class="px-1">
                                <div class="items-center flex justify-end text-gray-600">
                                    <popover-stock :ref="'stock'+i" :productId="item.productId">
                                        <autonumeric
                                            v-model="item.qty" 
                                            :ref="'qty'+i"
                                            @focus.native="$refs['stock'+i][0].onFocus()"  
                                            @blur.native="$refs['stock'+i][0].onFocusOut()" 
                                            :options="['integer', {modifyValueOnWheel : false}]" 
                                            :class="{'text-red-500': item.qty > item.stock}"
                                            class="border-b border-transparent group-hover:border-teal-500 text-gray-300 focus:border-teal-600 w-full focus:outline-none bg-gray-800 text-right"
                                            :placeholder="'qty'">
                                        </autonumeric>
                                    </popover-stock>
                                </div>
                            </td>
                            <td class="px-1">
                                <div class="flex justify-center items-center">
                                    <vue-discount v-model="item.discount" :total="item.total" :idx="item.idx" :symbol="'Rp'" :label="'Discount'"></vue-discount>
                                </div>
                            </td>
                            <td class="px-1">
                                <div class="items-center flex justify-end">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 0}).format(item.subTotal)}}</div>
                            </td>
                            <td class="px-1 text-center">
                                <button @click="showSerialNumber(item.idx)" v-if="item.isSerialNumber" :class="[item.qty == item.serialNumber.length ? 'text-teal-500 hover:text-teal-600': 'text-red-500 hover:text-red-600']" class="px-1 text-center w-full h-9">
                                    <i class="icon-list-unordered"></i>
                                </button>
                                <div v-else class="text-gray-600">
                                    <i class="icon-list-unordered"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-span-2 rounded-md relative h-full">
                <div class="mb-3 text-gray-600">
                    <label class="text-gray-300" for="">Customer *</label>
                    <autocomplete class="w-full bg-gray-800" :styleClass="'bg-black text-gray-300 h-9'" ref="autocomplete" @onSearchMore="modalCustomerSearch = true" @onEdit="onEditCreateCustomer($event)" :err="errCustomerId" v-model="customer" :label="customer.displayName" @input="onSelectCustomer($event)" :text="true" :url="'/customers/auto-search'" :placeholder="'Search customer by name or code'">
                        <template #default="item">
                            <div>
                                <span >{{item.item.displayName}}</span>
                                <div class="text-gray-400 text-xs">
                                    <span v-show="item.item.contact.mobile">{{item.item.contact.mobile}}</span>
                                    <span :class="{'ml-3': item.item.contact.mobile}" v-show="item.item.address">{{item.item.address.street}}</span>
                                </div>
                            </div>
                        </template>
                    </autocomplete>
                    <div class="text-red-500 pt-1">{{err.customerId}}</div>
                </div>
                <!-- <div class="bg-gray-800 rounded-md mb-1 pb-3">
                    <div class="grid grid-cols-2 mb-5">
                        <div class="col-span-1">
                            <button @click="onEditCreateCustomer" class="w-full h-9 bg-blue-600 text-blue-50 rounded-t-md rounded-r-none">NEW CUSTOMER</button>
                        </div>
                        <div class="col-span-1">
                            <button @click="modalCustomerSearch = true" class="w-full h-9 bg-teal-600 text-teal-50 rounded-t-md rounded-l-none">EXISTING</button>
                        </div>
                    </div>
                    <div :class="{'animated shake': err.customerId}">
                        <div class="flex items-center justify-center mb-1">
                            <div class="h-14 w-14 border-2 border-gray-500 rounded-full flex items-center justify-center overflow-hidden">
                                <i class="icon-user icon-3x"></i>
                            </div>
                        </div>
                        <div class="text-center">CUSTOMER</div>
                    </div>
                </div> -->

                <!-- LIST -->
                <ul ref="items" class="mb-1 space-y-1 overflow-y-scroll">
                    <li v-show="customer" class="relative group hover:text-gray-300">
                        <div class="bg-gray-700 pl-3 pr-5 flex items-center rounded-md py-3 top-0">
                           <button @click="onDeleteCustomer" href="#" class="text-red-500 pr-3"><i class="icon-trash"></i></button>
                           <div class="border-r border-gray-900 border-opacity-60 h-3.5"></div>
                           <div class="border-r border-gray-500 border-opacity-60 h-3.5"></div>
                           <button @click="onEditCustomer(customer)" href="#" class="text-blue-500 pl-3"><i class="icon-pencil"></i></button>
                        </div>

                        <div class="bg-gray-800 p-5 w-full transform duration-200 cursor-pointer flex items-center justify-between rounded-md py-3 absolute top-0 group-hover:translate-x-20">
                            <span class="truncate">{{customer.displayName || customer.name}}</span>
                        </div>
                    </li>
                </ul>
                <!--  -->
                <div class="bg-gray-800 p-5 h-full rounded-t-md">
                    <div class="text-gray-300 mb-5">
                        <div class="flex justify-between mb-1">
                            <div>Subtotal</div>
                            <div class="text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 0}).format(total)}}</div>
                        </div>
                        <div class="flex justify-between mb-2">
                            <div class="w-auto">
                                <vue-discount ref="discount" :idx="'discount'" :showText="true" v-model="form.discount" :total="total" :symbol="'Rp'" :label="'Discount on Total Price'"></vue-discount>
                            </div>
                            <div class="text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 0}).format(form.discount.value)}}</div>
                        </div>
                        <div class="flex justify-between mb-2">
                            <div>
                                <select-tax ref="tax" @input="onSelectTax" :options="taxCodes"></select-tax>
                            </div>
                            <div class="text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 0}).format(tax)}}</div>
                        </div>
                        <div class="border-b-2 border-dashed border-gray-600 mb-2"></div>
                        <div class="flex justify-between text-lg">
                            <div>Total</div>
                            <div class="text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 0}).format(grandTotal)}}</div>
                        </div>
                    </div>
                </div>
                <div class="bg-gray-800 absolute w-full rounded-md p-5 bottom-0">
                    <div class="mb-2">Payment Method</div>
                    <div class="flex items-center justify-between mb-1 space-x-2">
                        <div class="text-center w-full">
                            <button @click="paymentMethod = 'Cash'" :class="[paymentMethod == 'Cash' ? 'border bg-gray-50 text-teal-500':'border hover:text-teal-500 hover:border-teal-500']" class="border-gray-500 text-gray-500 rounded-md h-12 w-full">
                                <i class="icon-coin-dollar" style="font-size: 24px"></i>
                            </button>
                            <div class="mt-2">Cash</div>
                        </div>
                        <div class="text-center w-full">
                            <button @click="paymentMethod = 'Debit Card'" :class="[paymentMethod == 'Debit Card' ? 'border bg-gray-50 text-teal-500':'border hover:text-teal-500 hover:border-teal-500']" class="border border-gray-500 text-gray-500 rounded-md h-12 w-full">
                                <i class="icon-credit-card" style="font-size: 24px"></i>
                            </button>
                            <div class="mt-2">Debit Card</div>
                        </div>
                        <div class="text-center w-full">
                            <button @click="paymentMethod = 'Transfer Bank'" :class="[paymentMethod == 'Transfer Bank' ? 'border-0 bg-gray-50 text-teal-500':'border hover:text-teal-500 hover:border-teal-500']" class="border border-gray-500 text-gray-500 rounded-md h-12 w-full">
                                <i class="icon-link2" style="font-size: 24px"></i>
                            </button>
                            <div class="mt-2">Transfer</div>
                        </div>
                        <div class="text-center w-full">
                            <button @click="paymentMethod = 'Tokopedia'" :class="[paymentMethod == 'Tokopedia' ? 'border-0 bg-gray-50 text-teal-500':'border hover:text-teal-500 hover:border-teal-500']" class="border border-gray-500 text-gray-500 rounded-md h-12 w-full">
                                <i class="icon-store" style="font-size: 24px"></i>
                            </button>
                            <div class="mt-2">Tokopedia</div>
                        </div>
                    </div>
                    <div class="mb-5">
                        <button @click="paymentMethod = 'Multiple'" :class="[paymentMethod == 'Multiple' ? 'border-0 bg-gray-50 text-teal-500': 'border hover:text-teal-500 hover:border-teal-500']" class="w-full border border-gray-500 text-gray-500 h-11 rounded-md">MULTIPLE PAYMNET</button>
                    </div>
                    <div class="flex space-x-2">
                        <button @click="onCancel" class="h-11 w-full rounded-md bg-red-700 hover:bg-red-800">
                            DISCARD
                        </button>
                        <button @click="onProcess" :class="{'bg-gray-50 text-teal-600': paymentMethod}" class="h-11 w-full rounded-md border border-gray-500 text-gray-500">
                            PROCESS
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <modal-new-customer class="text-gray-600" v-model="customer" @input="onSelectCustomer($event)" :dataCustomer="dataCustomer" @onEditParent="onEditParent($event)" :title="modalTitle" :show="modalCustomer" @onClose="modalCustomer = false"></modal-new-customer>
        <div v-if="customer.parents">
            <div v-for="(parent, i) in customer.parents" :key="i">
                <modal-new-customer class="text-gray-600" v-model="dataParentEdit" :dataCustomer="dataParent[i]" :title="'EDIT CUSTOMER'" :show="modalParent[i].modal" @onEditParent="onEditParent($event)" @onClose="modalParent[i].modal=false"></modal-new-customer>
            </div>
        </div>
        <modal-customer-search class="text-gray-600" v-model="customer" @input="onSelectCustomer($event)" :show="modalCustomerSearch" @closeModal="modalCustomerSearch = false"></modal-customer-search>

        <!-- MODAL PROCESS -->
        <modal :show="modalPayment">
            <div class="w-2/4 bg-white rounded-sm text-gray-600">
                <div class="flex items-center justify-between px-5 py-3 border-b">
                    <div>PAYMENT</div>
                </div>
                <div class="grid grid-cols-2">
                    <div class="col-span-1 flex items-center justify-center p-5">
                       <div class="w-full px-5">
                            <div class="flex items-center justify-center mb-1">
                                <div class="h-14 w-14 border-2 border-gray-500 rounded-full flex items-center justify-center overflow-hidden">
                                    <i class="icon-user icon-3x"></i>
                                </div>
                            </div>
                            <div class="text-center">CUSTOMER</div>
                            <div class="font-medium text-center mb-5">{{customer.displayName || customer.name}}</div>
                            <div class="flex justify-between items-center">
                                <div>Subtotal</div>
                                <div>{{Intl.NumberFormat('en-US', {minimumFractionDigits: 0}).format(total)}}</div>
                            </div>
                            <div class="flex justify-between items-center">
                                <div>Discount</div>
                                <div>{{Intl.NumberFormat('en-US', {minimumFractionDigits: 0}).format(form.discount.value)}}</div>
                            </div>
                            <div class="flex justify-between items-center">
                                <div>Tax</div>
                                <div>{{Intl.NumberFormat('en-US', {minimumFractionDigits: 0}).format(tax)}}</div>
                            </div>
                            <div class="border-b-2 border-gray-400 border-dashed my-2"></div>
                            <div class="flex justify-between items-center text-lg">
                                <div>TOTAL</div>
                                <div>{{Intl.NumberFormat('en-US', {minimumFractionDigits: 0}).format(grandTotal)}}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="paymentMethod == 'Cash'" class="col-span-1 bg-gray-100 p-10">
                        <div class="text-center text-lg mb-5">{{paymentMethod}}</div>
                        <div class="mb-5">
                            <autonumeric
                                ref="cash"
                                v-model="cash"
                                :class="{'border-red-500': err.cash}"
                                @keydown.native="err.cash = ''"
                                class="form-control text-right"
                                :options="['integer', {modifyValueOnWheel : false}]" 
                            >
                            </autonumeric>
                        </div>
                        <div class="flex items-center justify-between pr-4">
                            <div>Change</div>
                            <div :class="{'text-red-500': changeCash < 0}">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 0}).format(changeCash)}}</div>
                        </div>
                    </div>
                    <div v-if="paymentMethod == 'Debit Card' || paymentMethod == 'Transfer Bank'" class="col-span-1 bg-gray-100 p-5">
                        <div class="text-center text-lg mb-5">{{paymentMethod}}</div>
                        <div class="mb-5">
                            <ul>
                                <li v-for="(bank, i) in banks" :key="i" class="mb-1">
                                    <label :for="'radio'+i" class="flex items-center w-full border p-3 cursor-pointer rounded-sm hover:bg-gray-50" :class="{'border-red-500': err.bankId}">
                                        <input v-model="form.bankId" :id="'radio'+i" name="bank" :value="bank._id" type="radio">
                                        <div class="ml-3">
                                            <div>{{bank.name}}</div>
                                            <div class="text-gray-400">{{bank.accountName}}</div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- TOKOPEDIA -->
                    <div v-if="paymentMethod == 'Tokopedia'" class="col-span-1 bg-gray-100 flex items-center justify-center w-full">
                        <div class="text-center w-full px-5">
                            <img src="@/assets/tokopedia.png" alt="">
                            <div class="font-semibold mt-1 mb-5">TOKOPEDIA</div>
                            <div class="flex flex-col w-full mb-2">
                                <select-2 v-model="form.shipping.shipmentMethodId" @input="onSelectShippmentMethodId" class="form-control" :options="shippings" :placeholder="'Kurir / Expedisi'">
                                    <option value=""></option>
                                </select-2>
                            </div>
                            <div class="flex flex-col w-full mb-2">
                                <select-2 ref="services" class="form-control" :options="shipmentServices" :placeholder="'Shipment Service'">
                                    <option value=""></option>
                                </select-2>
                            </div>
                            <div>
                                <autonumeric ref="shipmentCost" class="form-control mt-1.5 text-right" 
                                    :placeholder="'Shipment Cost'"
                                    v-model="form.shipping.shipmentCost"
                                    :options="{modifyValueOnWheel : false}"
                                >
                                </autonumeric>
                            </div>
                        </div>
                    </div>
                    <!-- MULTIPLE PAYMENT -->
                    <div v-if="paymentMethod == 'Multiple'" class="bg-gray-100 p-2">
                        <div class="flex items-center justify-between py-2 pl-1 text-red-500 pr-7">
                            <div>Amount Due</div>
                            <div class="text-right leading-none">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 0}).format(changeBank)}}</div>
                        </div>
                        <div class="text-center bg-blue-500 rounded-sm p-3 mb-2">
                            <div class="mb-2 text-blue-50 font-semibold">Cash</div>
                            <autonumeric
                                ref="cash"
                                v-model="multiplePayment.cash"
                                :class="{'border-red-500': errMultiplePayment.cash}"
                                @keydown.native="errMultiplePayment.cash = ''"
                                class="form-control text-right"
                                :options="['integer', {modifyValueOnWheel : false}]" 
                            >
                            </autonumeric>
                        </div>
                        <div class="p-2 bg-white rounded-sm border">
                            <div class="flex justify-center space-x-5 py-1.5 mb-2 border rounded-sm" :class="{'border-red-500': errMultiplePayment.journalBank}">
                                <div class="form-radio">
                                    <input v-model="multiplePayment.journalBank" :value="'Debit'" type="radio" id="debit" name="payment">
                                    <label for="debit">Debit Card</label>
                                </div>
                                <div class="form-radio">
                                    <input v-model="multiplePayment.journalBank" :value="'Transfer'" type="radio" id="transfer" name="payment">
                                    <label for="transfer">Transfer</label>
                                </div>
                            </div>
                            <ul>
                                <li v-for="(bank, i) in banks" :key="i" class="mb-1">
                                    <label :for="'radio'+i" class="flex items-center w-full border p-3 cursor-pointer rounded-sm hover:bg-gray-50" :class="{'border-red-500': errMultiplePayment.bankId}">
                                        <input v-model="multiplePayment.bankId" :id="'radio'+i" name="bank" :value="bank._id" type="radio">
                                        <div class="ml-3">
                                            <div>{{bank.name}}</div>
                                            <div class="text-gray-400">{{bank.accountName}}</div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="text-right border-t py-2 px-5">
                    <button @click="modalPayment = false" class="h-9 border rounded-sm bg-gray-50 px-5">CANCEL</button>
                    <button @click="onConfirm" :disabled="isDisabled" class="h-9 rounded-sm bg-blue-500 text-blue-50 px-5 ml-2">CONFIRM</button>
                </div>
            </div>
        </modal>
        
        <modal-center :show="modalPrint">
            <div class=" w-2/5 bg-white rounded-sm p-10">
                <div class="flex items-center justify-center mb-5">
                    <div class="h-28 w-28 rounded-full border-4 flex items-center border-green-500 justify-center text-green-500">
                        <i class="icon-checkmark2 icon-5x"></i>
                    </div>
                </div>
                <div class="text-2xl text-green-500 text-center mb-5">Thank you for purchashing</div>
                <div class="text-gray-400 mb-5">
                    <div class="font-semibold">Grand Total Amount</div>
                    <div class="text-3xl font-semibold text-gray-600">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 0}).format(formPrint.grandTotal)}}</div>
                </div>
                <div class="text-gray-400 mb-5">
                    <div class="font-semibold">Payment Method</div>
                    <div class="text-xl font-semibold text-gray-600">{{formPrint.paymentMethod}}</div>
                </div>
                <div class=" flex items-center justify-center text-gray-500 space-x-2">
                    <button @click="onPrintStruk" class="border-2 border-gray-600 rounded-sm h-9 px-5 relative">
                        <div v-if="isDisabledStruk" class="absolute bg-teal-400 w-full h-full inset-0 rounded-sm flex items-center justify-center">
                            <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                        Print Thermal
                    </button>
                    <button @click="onPrint" class="border-2 border-green-500 rounded-sm h-9 px-5 relative">
                        <div v-if="isDisabled" class="absolute bg-teal-400 w-full h-full inset-0 rounded-sm flex items-center justify-center">
                            <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                        Print A4 Invoice
                    </button>
                    <button @click="onCancelPrint" class="border-2 border-red-500 rounded-sm h-9 px-5">DISCARD</button>
                </div>
            </div>
        </modal-center>

        <!-- MODAL SERIAL NUMBER -->
        <modal :show="modalSerialNumber" class="text-gray-500">
            <div class="w-2/5 bg-white rounded-sm border">
                <form @submit.prevent="onSubmitSerialNumber">
                    <div class="flex items-center justify-between px-5 py-3 border-b">
                        <div>SERIAL NUMBER</div>
                    </div>
                    <div class="p-5">
                        <div class="mb-3">{{formSerialNumber.name}}</div>
                        <table class="w-full">
                            <tr v-for="(sn, i) in formSerialNumber.serialNumber" :key="i">
                                <td class="py-1" style="width:6%">#{{i+1}}<td>
                                <td class="py-1">
                                    <input v-model="sn.sn" :ref="'sn'+i" type="text" class="form-control" :class="{'border-red-500': formSerialNumber.errSerialNumber[i].err}" placeholder="Serial Number">
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="py-2 text-right px-5 border-t">
                        <button @click="modalSerialNumber=false" type="button" class="h-9 px-5 rounded-sm hover:bg-gray-100 hover:border-gray-200 mr-2">CANCEL</button>
                        <button type="submit" class="h-9 px-5 rounded-sm text-teal-50 bg-teal-500 hover:bg-teal-600">APPLY</button>
                    </div>
                </form>
            </div>
        </modal>
        <div class=" hidden">
            <invoice-pdf :data="invoice" id="printJS-form"></invoice-pdf>
        </div>
        <div class="hidden">
            <struk-pdf :data="struk" id="print-Struk"></struk-pdf>
        </div>
    </div>
</template>

<script>
import AutoSearch from '../../components/autoSearchPos.vue'
import Autonumeric from '../../components/Autonumeric.vue'
import Autocomplete from '../../components/Autocomplete.vue'
import popoverStock from '../../components/popoverStock.vue'
import VueDiscount from '../../components/discountPos.vue'
import SelectTax from '../../components/taxPos.vue'
import axios from 'axios'
import ModalNewCustomer from '../../components/modalCustomer.vue'
import ModalCustomerSearch from '../../components/modalCustomerSearch.vue'
import Modal from '../../components/Modal.vue'
import Swal from 'sweetalert2/dist/sweetalert2'
import ModalCenter from '../../components/ModalCenter.vue'
import printJS from 'print-js'
import invoicePdf from '../../components/invoicePdf.vue'
import strukPdf from '../../components/struk.vue'
import Select2 from '../../components/Select2.vue'
export default {
    components: {AutoSearch, Autonumeric, Autocomplete, popoverStock, VueDiscount, SelectTax, ModalNewCustomer, ModalCustomerSearch, Modal, ModalCenter, invoicePdf, strukPdf, Select2},
    data () {
        return {
            amount: '',
            isDisabled: false,
            isDisabledStruk: false,
            imgURL: process.env.VUE_APP_ROOT_API,
            modalSerialNumber: false,
            modalPrint: false,
            modalCustomer: false,
            modalCustomerSearch: false,
            modalTitle: '',
            dataCustomer: '',
            modalParent: [],
            dataParent: [],
            dataParentEdit: '',
            modalPayment: false,
            customer: '',
            taxCodes: [],
            banks: [],
            cash: '',
            paymentMethod: '',
            shippings: [],
            multiplePayment: {
                cash: '',
                bank: '',
                journalBank: '',
                bankId: '',
            },
            errMultiplePayment: {
                cash: '',
                bank: '',
                journalBank: '',
                bankId: '',
            },
            form: {
                customerId: '',
                items: [],
                tax: {
                    code: '',
                    amount: '',
                    value: ''
                },
                discount: {
                    discountType: '',
                    amount: '',
                    value: ''
                },
                shipping: {
                    shipmentMethodId: '',
                    shipmentService: '',
                    shipmentCost: '',
                    bookingCode: '',
                    trackingNumber: ''
                },
                total: '',
                grandTotal: '',
                paymentMethod: '',
                bankId: '',
                multiplePayment: ''
            }, 
            err: {
                customerId: '',
                cash: '',
                bankId: '',
            },
            formSerialNumber : {
                index: '',
                name: '',
                serialNumber: [],
                errSerialNumber: []
            },
            formPrint: {
                grandTotal: '',
                paymentMethod: ''
            },
            invoiceId: '',
            invoice: {
                invoice: {
                    shipping: ''
                }
            },
            struk:'',
            onCheckSerialNumber: false
        }
    },
    mounted () {
        let h = window.innerHeight - 49
        this.$refs.content.style.height = h+'px'
        this.$refs.items.style.maxHeight = h - 360+'px'
        this.getData()
    },
    computed: {
        productIds () {
            let id = [""]
                for(let i=0; i < this.form.items.length; i++) {
                    id.push(this.form.items[i].productId)
                }
            return id
        },
        items () {
            let item = this.form.items.map (obj => {
                obj.total = obj.unitPrice * obj.qty
                obj.subTotal = obj.total - obj.discount.value
                return obj
            })

            return item.reverse()
        },
        total () {
            let total = 0
            for (let i = 0; i < this.form.items.length; i++) {
                total += this.form.items[i].subTotal
            }
            return total
        },
        tax () {
            let t = 0
            if(this.form.tax.amount) {
                t = (this.total - this.form.discount.value) * this.form.tax.amount / 100
            }
            return t
        },
        grandTotal () {
            let g = 0
            g = this.total + this.tax + this.form.shipping.shipmentCost - this.form.discount.value
            return g
        },
        changeCash () {
            let change = 0
            change = this.cash - this.grandTotal
            return change
        },
        changeBank () {
            let c = 0
            c = this.multiplePayment.cash - this.grandTotal
            return c
        },
        formSubmit () {
            for (let i in this.form) {
                if(this.form[i] === '') {
                    delete this.form[i]
                }
            }
            return this.form
        },
        shipmentServices () {
            let services =  this.shippings.find(obj => obj.id == this.form.shipping.shipmentMethodId)
            if(services) {
                return services.services.map(obj => {
                    obj.id = obj.name,
                    obj.text = obj.name
                    return obj
                })
            }
            return []
        },
    },
    methods: {
        getData () {
            axios.get('/pos/create')
            .then(res => {
                this.taxCodes = res.data.taxCodes
                this.banks = res.data.banks
            })
        },
         // EDIT & CREATE CUSTOMER
        onEditCreateCustomer (e) {
            this.dataCustomer = e
            this.modalCustomer = true
            this.modalTitle = 'CREATE CUSTOMER'
        },
        onEditCustomer (e) {
            axios.get('/customers/edit/'+e._id)
            .then(res => {
                this.modalTitle = 'EDIT CUSTOMER'
                this.dataCustomer = res.data
                this.modalCustomer = true
            })
        },
        onEditParent (param) {
            let index = this.customer.parents.length - (param.i +1)
            axios.get('/customers/edit/'+param.id)
            .then(res => {
                this.dataParent[index] = res.data
                this.modalParent[index].modal = true
            })
        },
        onSelectCustomer (e) {
            this.err.customerId = ''
            this.form.customerId = e._id
            this.modalCustomerSearch = false
        },
        onDeleteCustomer () {
            this.form.customerId = ''
            this.customer = ''
        },
        onSelectItem (e) {
            this.form.items.push({idx: Date.now(), image: e.images[0], productId: e._id, name: e.name, isSerialNumber: e.isSerialNumber, serialNumber: [], sellingPrice: e.sellingPrice, unitPrice: e.sellingPrice, stock: e.stock, qty: 1, total: 0, discount: {discountType: '', amount: '', value: ''}, subTotal: '', hover: false, edited: false})
            this.$nextTick(() => {
                this.$refs.unitPrice0[0].$el.focus()
            })
        },
        onEnterUnitPrice (i) {
            this.$refs['qty'+i][0].$el.focus()
        },
        onDeleteItem (i) {
            let idx = this.form.items.findIndex(obj => obj.idx == i)
            this.form.items.splice(idx, 1)
        },
        onSelectTax (e) {
            this.form.tax.code = e.code
            this.form.tax.amount = e.amount
        },
        onProcess () {
            this.onCheckSerialNumber = false
            if(!this.form.customerId) {
                this.err.customerId = 'Customer beluh di pilih'
                return
            }
            for (let el of this.form.items) {
                if(el.isSerialNumber && el.qty != el.serialNumber.length) {
                    this.showSerialNumber(el.idx)
                    this.onCheckSerialNumber = true
                    return
                }
            }
            if(this.paymentMethod) {
                this.modalPayment = true
                this.onCheckSerialNumber = false
            }
        },
        onConfirm () {
            if(this.paymentMethod == 'Cash' && !this.cash) {
                this.err.cash = true
                return
            }
            if(this.paymentMethod == 'Cash' && this.cash < this.total) {
                this.err.cash = true
                return
            }
            if(this.paymentMethod == 'Debit Card' && !this.form.bankId) {
                this.err.bankId = true
                return
            }
            if(this.paymentMethod == 'Transfer Bank' && !this.form.bankId) {
                this.err.bankId = true
                return
            }
            if(this.paymentMethod == 'Multiple') {
                if(!this.multiplePayment.cash) {
                    this.errMultiplePayment.cash = true
                    return
                }
                if(!this.multiplePayment.journalBank) {
                    this.errMultiplePayment.journalBank = true
                    return
                }
                if(!this.multiplePayment.bankId) {
                    this.errMultiplePayment.bankId = true
                    return
                }
            }
            this.isDisabled = true
            this.form.grandTotal = this.grandTotal
            this.form.total = this.total
            this.form.paymentMethod = this.paymentMethod
            this.form.tax.value = this.tax
            this.form.multiplePayment = this.multiplePayment
            axios.post('/pos/insert', this.formSubmit)
            .then((res) => {
                this.invoiceId = res.data.invoiceId
                this.formPrint.grandTotal = this.grandTotal
                this.formPrint.paymentMethod = this.paymentMethod
                this.isDisabled = false
                this.modalPayment = false
                this.modalPrint = true
                this.onClear()
            })
        },
        onClear () {
            this.paymentMethod = ''
            this.multiplePayment = {
                cash: '',
                bank: '',
                journalBank: '',
                bankId: '',
            }
            this.$refs.discount.onClear()
            this.$refs.tax.onClear()
            this.customer = ''
            this.form = {
                customerId: '',
                items: [],
                tax: {
                    code: '',
                    amount: '',
                    value: ''
                },
                discount: {
                    discountType: '',
                    amount: '',
                    value: ''
                },
                shipping: {
                    shipmentMethodId: '',
                    shipmentService: '',
                    shipmentCost: '',
                    bookingCode: '',
                    trackingNumber: ''
                },
                total: '',
                grandTotal: '',
                paymentMethod: '',
                bankId: '',
                multiplePayment: '',
            } 
        },
        onCancel () {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                cancelButtonClass: 'bg-red-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-red-600',
                preConfirm: function () {
                    return 
                },
                allowOutsideClick: false
            })
            .then(res => {
                if(res.value) {
                    this.onClear()
                }
            })
        },
        showSerialNumber (idx) {
            this.modalSerialNumber = true
            let i = this.form.items.findIndex(obj => obj.idx == idx)
            let el = this.form.items[i]
            this.formSerialNumber.index = i
            this.formSerialNumber.name = el.name
            if(el.isSerialNumber && el.serialNumber.length == 0) {
                let a = el.qty
                for (let i=0; i < a; i++) {
                    this.formSerialNumber.serialNumber.push({sn: ''})
                    this.formSerialNumber.errSerialNumber.push({err: ''})
                }
            }
            if(el.isSerialNumber && el.serialNumber.length < el.qty) {
                this.formSerialNumber.serialNumber = JSON.parse(JSON.stringify(el.serialNumber))
                for(let i=0; i < el.serialNumber.length; i++) {
                    this.formSerialNumber.errSerialNumber.push({err: ''})
                }
                let a = el.qty - el.serialNumber.length
                for(let i=0; i< a; i++) {
                    this.formSerialNumber.serialNumber.push({sn: ''})
                    this.formSerialNumber.errSerialNumber.push({err: ''})
                }
            }
            if(el.isSerialNumber && el.serialNumber.length > el.qty) {
                this.formSerialNumber.serialNumber = JSON.parse(JSON.stringify(el.serialNumber))
                for(let i=0; i < el.serialNumber.length; i++) {
                    this.formSerialNumber.errSerialNumber.push({err: ''})
                }
                let a = el.serialNumber.length - el.qty
                let i = el.qty
                this.formSerialNumber.serialNumber.splice(i, a)
                this.formSerialNumber.errSerialNumber.splice(i, a)
            }
            if(el.serialNumber.length == el.qty) {
                this.formSerialNumber.serialNumber = JSON.parse(JSON.stringify(el.serialNumber))
                for(let i=0; i < el.serialNumber.length; i++) {
                    this.formSerialNumber.errSerialNumber.push({err: ''})
                }
            }
            return true
        },
        onSubmitSerialNumber () {
            let app = this
            for(let i=0; i < this.formSerialNumber.serialNumber.length; i++) {
                let el = this.formSerialNumber.serialNumber[i]
                if(!el.sn) {
                    this.formSerialNumber.errSerialNumber[i].err = true
                } else {
                    this.formSerialNumber.errSerialNumber[i].err = false
                }
            }

            if(emptySerialNumber()) {
                let i = this.formSerialNumber.index
                this.form.items[i].serialNumber = this.formSerialNumber.serialNumber
                this.modalSerialNumber = false
                if(this.onCheckSerialNumber) {
                    this.$nextTick(() => {
                        this.onProcess()
                    })
                }
            }

            function emptySerialNumber () {
                for (let i=0; i < app.formSerialNumber.serialNumber.length; i++) {
                    const el = app.formSerialNumber.serialNumber[i]
                    if(!el.sn) {
                        return false
                    }
                } 
                return true
            }
        },
        onPrint () {
            this.isDisabled = true
            axios.get('/invoices/detail/'+this.invoiceId)
            .then(res => {
                this.invoice = res.data
                if(!this.invoice.invoice.shipping) {
                    this.invoice.invoice.shipping = {
                        shipmentMethodId: '',
                        shipmentService: '',
                        shipmentCost: 0,
                        bookingCode: '',
                        trackingNumber: '',
                    }
                }
                let invoiceNo = res.data.invoice.invoiceNo
                let title = invoiceNo.replace(/\//g, ".") +" ("+ res.data.invoice.billTo.customer.name+")"
                document.title = title
                this.$nextTick(() => {
                    printJS({
                        printable: 'printJS-form', 
                        type: 'html',
                        css: 'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
                        scanStyles: false,
                        onPrintDialogClose: () => this.onCancelPrint()
                    })
                })
            })
        },
        onPrintStruk () {
            this.isDisabledStruk = true
            axios.get('/invoices/detail/'+this.invoiceId)
            .then(res => {
                this.struk = res.data
                let invoiceNo = res.data.invoice.invoiceNo
                let title = invoiceNo.replace(/\//g, ".") +" ("+ res.data.invoice.billTo.customer.name+")"
                document.title = title
                this.$nextTick(() => {
                    printJS({
                        printable: 'print-Struk', 
                        type: 'html',
                        css: 'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
                        scanStyles: false,
                        onPrintDialogClose: () => this.onCancelPrint()
                    })
                })
            })
        },
        onCancelPrint () {
            this.isDisabled = false
            this.isDisabledStruk = false
            document.title = 'APP DUTAPERSADA'
            this.modalPrint = false
            this.formPrint.grandTotal = ''
            this.formPrint.paymentMethod = ''
            this.invoice = ''
        },
        onSelectShippmentMethodId () {
            this.$nextTick (() => {
                this.$refs.services.open()
            })
        },
    },
    watch: {
        dataParentEdit (val) {
            for(let i=0; i < this.dataParent.length; i++) {
                const el = this.dataParent[i]
                if(el.parents) {
                    let index = el.parents.findIndex(x => x._id == val._id)
                    if(el.parents[index]) {
                        el.parents[index].name = val.name
                    }
                }
            }
            let index = this.dataCustomer.parents.findIndex(x => x._id == val._id)
            this.dataCustomer.parents[index].name = val.name
        },
        customer (val) {
            if(val.parents) {
                this.modalParent = []
                this.dataParent = []
                for (let i = 0; i < val.parents.length; i++) {
                    this.modalParent.push({modal: false})
                    this.dataParent.push('')
                }
            }
        },
        paymentMethod (val) {
            this.cash = 0
            this.form.bankId = ''
            if(val == 'Tokopedia') {
                axios.get('/setting/method')
                .then(res => {
                    this.shippings = res.data
                })
            }
        },
        modalPayment (val) {
            if(val) {
                this.multiplePayment = {
                    cash: '',
                    bank: '',
                    journalBank: '',
                    bankId: '',
                }
                this.errMultiplePayment = {
                    cash: '',
                    bank: '',
                    journalBank: '',
                    bankId: '',
                }
                this.$nextTick(() => {
                    if(this.paymentMethod == 'Cash' || this.paymentMethod == 'Multiple') {
                        this.$refs.cash.$el.focus()
                    }
                })
            }
        },
        modalSerialNumber (val) {
            if(!val) {
                this.formSerialNumber.name = ''
                this.formSerialNumber.index = ''
                this.formSerialNumber.serialNumber = []
                this.formSerialNumber.errSerialNumber = []
            }
        },
        'form.bankId': function () {
            this.err.bankId = ''
        },
        'multiplePayment.cash': function (val) {
            let bank = this.grandTotal - val
            this.multiplePayment.bank = bank
        },
        'multiplePayment.bankId': function () {
            this.errMultiplePayment.bankId = ''
        },
        'multiplePayment.journalBank': function () {
            this.errMultiplePayment.journalBank = ''
        }
    }
}
</script>

<style>
    .modal {
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: none !important;
        animation-duration: 1s;
        animation-fill-mode: none !important;
    }
</style>